<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, email,numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import API from "@/api.js";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Elenco operatori",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Operatori",
      items: [
        {
          text: "Operatori",
        },
        {
          text: "Elenco operatori",
          active: true,
        },
      ],
      inserting: false,
      dataReady: false,
      userList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      store: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store : this.$auth.user().doctor.store,
      fields: [
        {
          key: "check",
          label: "",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "email",
          label: "E-mail"
        },
        "Azioni",
      ],
      
      // set in update status

      edit:true,

      changePSWModal:false,

      genData: false,
      permData: false,
      accessData: false,

      // set new insert status
      new:false,

      eventModal: false,
      submitted:false,
      deleteModal:false,
      curId:undefined,
       
      // color array
      colorIcon : ['#84acd7','#80d9ff','#fb503b','#fb913b','#8bbe1b'],
      currentUser: {
        name: "",
        lastname: "",
        bDate:"",
        // email:"",
        store: this.$auth.user().store.id,
        phone:"",
        color:"",
        machineFlag:false,
        appFlag:false,
        memoFlag:false,
        account_email: "",
      },


    };
  },
  validations: {
    currentUser: {
      name: { required },
      lastname: { required },
      bDate: { required },
      email: { required, email },
      phone:{required, numeric},
      account_email: { required, email },
    },


    validationUpdate: [
      'currentUser.name',
      'currentUser.lastname',
      'currentUser.bDate',
      'currentUser.email',
      'currentUser.phone'
    ],

    validationInsert: [
      'currentUser.name',
      'currentUser.lastname',
      'currentUser.bDate',
      // 'currentUser.email',
      'currentUser.phone',
      'currentUser.account_email'
    ]

  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.userList.length;
    },
  },

  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length;

    // this.axios.get(API.getDoctors.path()).then(response => {
    //   this.userList=response.data;
    // }).catch(error=>{
    //   console.log(error);

    // });

    this.reloadList();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

   
    insertUser() {
      this.currentUser = {};

      this.edit=false;
      
      this.genData= true,
      this.permData= true,
      this.accessData= true,

      this.curId=undefined;

      this.currentUser.color=this.colorIcon[Math.floor(Math.random() * (5))];

      this.eventModal = true;
      //this.new= true;
    },

    selectUser(item) {
      this.edit=true;

      this.genData= true,
      this.permData= false,
      this.accessData= false,

      this.$v.$reset();
      this.currentUser.name=item.name;
      this.currentUser.lastname=item.lastname;
      this.currentUser.bDate=item.bDate;
      this.currentUser.email=item.email;
      this.currentUser.phone=item.phone;

      this.eventModal = true;
    },

    changePassword(id){
      this.curId=id;
      this.changePSWModal=true;
    },

    confirmDelete(id){
      this.deleteModal=true;
      this.curId=id;
    },

    async handleSubmit(){
      this.submitted=true;
      this.inserting = true;
      this.$v.$touch();
      if(this.curId) {
        // update doctor
        if (this.$v.validationUpdate.$invalid) {
          return;
        } else {
          await this.axios.put(API.updateDoctor.path(this.curId),this.currentUser).then(response =>{
            this.responsemsg("Dati aggiornati","success");
            this.closeModal();
            console.log(response);  
          }).catch(error =>{
            this.responsemsg("Operazione fallita","error");
            console.log(error);

          })
        }

      } else {
        // insert doctor
        if (this.$v.validationInsert.$invalid) {
          return;
        } else {
          let doctorObj = {
            ...this.currentUser,
            store: this.$auth.user().store.id,
            email: this.currentUser.account_email,
          };
          doctorObj.account = {
            first_name: this.currentUser.name,
            last_name: this.currentUser.lastname,
            email: this.currentUser.account_email,
            password: "randomGen1!",
          }
          delete doctorObj.account_email;
          try {
            const r = await this.axios.post(API.postDoctor.path(),doctorObj);
            if (r.status === 201) {
              this.responsemsg("Nuovo operatore aggiunto","success");
              this.closeModal();
            } else {
              this.responsemsg("Operazione fallita","error");
              this.closeModal();
            }
          } catch (e) {
            this.responsemsg("Operazione fallita","error");
            this.closeModal();
          }
        }

      }
      this.inserting = false;
      
      this.reloadList();
      
    },

    
    updateUser(item) {
      this.edit=false;

      this.genData= true,
      this.permData= true,
      this.accessData= false,


      this.$v.$reset();
      this.curId=item.id;
      this.currentUser.name=item.name;
      this.currentUser.lastname=item.lastname;
      this.currentUser.bDate=item.bDate;
      this.currentUser.email=item.email;
      this.currentUser.phone=item.phone;
      this.currentUser.machineFlag=item.machineFlag;
      this.currentUser.appFlag=item.appFlag;
      this.currentUser.memoFlag=item.memoFlag;

      this.eventModal = true;
    },


    async handleDelete(){
       
      
      await this.axios.delete(API.deleteDoctor.path(this.curId)).then(response =>{
        this.responsemsg("Dottore Eliminato","success");
        this.closeDeleteModal();  
        console.log(response);
      }).catch(error => {
        this.responsemsg("Operazione fallita","error");
        console.log(error);

      });

      this.reloadList();


    },

    

    closeModal() {
      this.eventModal = false;
      this.currentUser = {};
      
      this.new=false;
      this.edit=true;
      this.submitted=false;

      this.changePSWModal=false;
      

      this.genData= false,
      this.permData= false,
      this.accessData= false,

      this.$v.$reset();
    },

    closeDeleteModal(){
      this.deleteModal=false;
      this.cur=undefined;
    },

    
    async reloadList(){
      
      this.totalRows = this.items.length;
      this.dataReady = false;
      this.axios.get(API.getDoctorsByStore.path(this.store.id)).then(response => {
        this.userList=response.data;
        this.dataReady = true;
      }).catch(error=>{
        console.log(error);
        this.dataReady = true;
      });

    },

    responsemsg(msg,type) {
      Swal.fire({
        position: "center",
        icon: type,
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row" style="min-height: 78vh;">
      <div class="col-12" v-if="dataReady === true && inserting === false">
        <!-- Card Start -->
        <div class="card">
          <div class="card-body">

            <!-- Button New Doctor -->
            <div class="row justify-content-end">
              <div class="col-auto text-right">
                <div class="mb-3">
                  <a href="javascript:void(0);" class="btn btn-primary" @click="insertUser()"
                    ><i class="mdi mdi-plus me-2"></i> Aggiungi operatore</a
                  >
                </div>
              </div>
            </div>
            <!-- end Button New Section -->

            <!--start setting view bar -->
            <div class="row mt-4">
              <!-- row setting box -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Visualizza  &nbsp; 
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;risultati
                  </label>
                </div>
              </div>
              <!-- end row setting box -->
              <!-- Search box -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Cerca..."
                      class="form-control rounded bg-light border-0 ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!--end setting view bar -->
            <!--Start Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="table table-centered table-nowrap"
                :items="userList"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(name)="data">
                  <div                   
                    class="avatar-xs d-inline-block me-2"
                  >
                    <div
                      class="avatar-title rounded-circle"
                      :style="`background-color:${data.item.color};`"
                    >
                      <i class="uil uil-user font-size-18"></i>
                    </div>
                  </div>
                  <span class="text-body">{{ data.item.name }} {{ data.item.lastname }}</span>
                </template>
                <template v-slot:cell(azioni)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2"
                        v-b-tooltip.hover
                        title="Anteprima"
                        style="color:#0071DC"
                        @click="selectUser(data.item)"
                        >
                        <i class="uil uil-eye font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="px-2"
                        style="color:#fb913b"
                        v-b-tooltip.hover
                        title="Modifica"
                        @click="updateUser(data.item)"
                       >
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <!-- <b-dropdown
                      class="list-inline-item"
                      variant="white"
                      right
                      toggle-class="text-muted font-size-18 px-2"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-v"></i>
                      </template>

                      <a class="dropdown-item" @click="changePassword(data.item.id)" >Cambia password</a>
                      <router-link :class="{'dropdown-item':true}" :to="{ name: 'doctorReport', params: { id: data.item.id}}">Storico esercizi</router-link>
                      <a class="dropdown-item" @click="confirmDelete(data.item.id)">Elimina</a>
                    </b-dropdown> -->
                  </ul>
                </template>
              </b-table>
            </div>
            <!-- end table -->
            <!-- pagination -->
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <!--end pagination -->

            <!--Data Modal start -->
            <b-modal
              v-model="eventModal"
              title="Edit Event"
              size="lg"
              title-class="text-black font-18"
              hide-footer
              body-class="p-3"
            >
              <div slot="modal-title"><i class="uil uil-user font-size-18"></i> Anagrafica operatore</div>
              <!-- form data -->
              <form @submit.prevent="handleSubmit">
                <!--general data-->
                <div class="row">
                  <!-- input name -->
                  <div class="col-xl-6 col-12">
                    <div class="mb-3">
                      <label for="name" class="form-label">Nome</label>
                      <input
                        id="name"
                        v-model="currentUser.name"
                        type="text"
                        :disabled="edit === true"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currentUser.name.$error }"
                        placeholder="Inserisci Nome"
                      />
                      <div v-if="submitted && this.$v.currentUser.name.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.name.required">Campo obbligatorio</span>
                      </div>
                    </div>
                  </div>

                  <!--input last name-->
                  <div class="col-xl-6 col-12">
                    <div class="mb-3">
                      <label class="control-label form-label">Cognome</label>
                      <input
                          id="lastname"
                          v-model="currentUser.lastname"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && this.$v.currentUser.lastname.$error }"
                          :disabled="edit === true"
                          placeholder="Inserisci cognome"
                        />
                        <div v-if="this.submitted && this.$v.currentUser.lastname.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.lastname.required">Campo obbligatorio</span>
                      </div>
                    </div>
                  </div>

                  <!-- input phone -->
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="control-label form-label">Telefono</label>
                      <input
                          id="phone"
                          v-model="currentUser.phone"
                          type="text"
                          class="form-control"
                          :disabled="edit === true"
                          :class="{ 'is-invalid': submitted && this.$v.currentUser.phone.$error }"
                          placeholder="Iserire telefono"
                      />
                        <div v-if="submitted && this.$v.currentUser.phone.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.phone.required">Campo obbligatorio</span>
                          <span v-if="!this.$v.currentUser.phone.numeric">Valori non ammessi</span>
                      </div>
                    </div>
                  </div>

                  <!-- input bdate -->
                  <div class="col-6">
                    <div class="mb-3">
                      <label class="control-label form-label">Data di Nascita</label>
                        <b-form-input
                          id="bDate"
                          v-model="currentUser.bDate"
                          type="date"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && this.$v.currentUser.bDate.$error }"
                          :disabled="edit === true"
                          placeholder="Inserire data"   
                        ></b-form-input>
                        <div v-if="submitted && this.$v.currentUser.bDate.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.bDate.required">Campo obbligatorio</span>
                      </div>
                    </div>
                  </div>
                  <!-- end general data-->
                </div>


                <!-- password-->
                <div class="row" v-if="this.accessData===true" >
                  <!-- <div class="col-12">
                    <hr>
                    <p>Account</p>
                  </div> -->
                  <!-- input email -->
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="control-label form-label"> Indirizzo E-mail</label>
                      <input
                          id="email"
                          v-model="currentUser.account_email"
                          type="text"
                          class="form-control"
                          :disabled="edit === true"
                          :class="{ 'is-invalid': submitted && this.$v.currentUser.account_email.$error }"
                          placeholder="Inserisci e-mail"
                      />
                        <div v-if="submitted && this.$v.currentUser.email.$error"  class="col-12 invalid-feedback d-block">
                          <span v-if="!this.$v.currentUser.account_email.required">Campo obbligatorio</span>
                          <span v-if="!this.$v.currentUser.account_email.email">Indirizzo e-mail non valido</span>                      
                      </div>
                    </div>
                  </div>
                  <!--end password-->
                </div>
                  <!-- end system information -->   
                <div class="p-3 form-group">
                  <b-button v-if="!edit" type="submit" class="ms-1" variant="primary" 
                    >Salva</b-button
                  >
                  <b-button v-if="edit" @click="closeModal" class="ms-1" variant="primary" 
                    >Storico</b-button
                  >
                  &nbsp; &nbsp;
                  <b-button variant="outline-primary" @click="closeModal">Annulla</b-button>
                </div>
              </form>
            </b-modal>
            <!--Data Modal end -->


            <!--Delete Modal start -->
            <b-modal
              v-model="deleteModal"
              title="Delete Event"
              title-class="text-black font-18"
              hide-footer
              body-class="p-3"
            >
              <div slot="modal-title"><i class="uil uil-user font-size-18"></i> Elimina Profilo</div>
              <form @submit.prevent="handleDelete">
                <div class="row">
                  <div class="col-12">
                    <p>Sei sicuro di voler eliminare l'utente?</p>
                  </div>  
                </div>
                <div class="p-3 form-group">
                  <b-button variant="danger" @click="closeDeleteModal">Annulla</b-button>
                  <b-button type="submit" class="ms-1" variant="success" 
                    >Conferma</b-button
                  >
                </div>
              </form>
            </b-modal>
            <!--Delete Modal end -->
          </div>
        </div>
      </div>
      <div class="col-12 text-center align-self-center" v-else-if="dataReady === false || inserting === true">
          <div class="lds-dual-ring"></div>
      </div>
    </div>
  </Layout>
</template>
